import React from 'react';
import CartIconImage from '../../../assets/images/cart-icon.svg';
import {
  CategoriesHeader,
  BasketCount,
  IconButton,
  SecondaryButton,
  LoyaltyBanner,
} from '@fingermarkglobal/cringer.components';
import ArrowRight from 'heroicons/solid/arrow-right.svg';

const Header = ({
  title = null,
  total = null,
  previousText = 'Back',
  previousAction = null,
  allowPreviousAction = true,
  className = '',
  count = 0,
  showBasketCount = true,
  showCategoriesHeader = false,
  onBasketClick = () => {},
  showMenuAction = true,
  menuText = 'Menu',
  menuAction = () => {},
  basketText = null,
  currentRoute = '',
  showSubtotal = true,
  couponText = 'Enter offer',
  isCouponEnabled = false,
  showCouponButton = false,
  couponAction = () => {},
  computedAction = () => {},
  showLoyaltyBanner = false,
} = {}) => {
  const showBasketText = !currentRoute.match('/cart');

  return (
    <div data-test="header" className={`flex flex-col ${className}`}>
      {title && (
        <div className={`${showCategoriesHeader ? `py-2 space-y-2` : `py-16 space-y-8`}`}>
          {showCategoriesHeader && <CategoriesHeader />}
          {showLoyaltyBanner && <LoyaltyBanner computedAction={computedAction} />}
          <div className="px-8 font-bold uppercase text-7xl">{title}</div>
        </div>
      )}
      <div className="flex items-center justify-between px-8 py-6 bg-accessory">
        <div className="flex">
          {showMenuAction && (
            <SecondaryButton onClick={menuAction} data-test="menu-button">
              {menuText}
            </SecondaryButton>
          )}
          {!!previousAction && (
            <IconButton
              disabled={!allowPreviousAction}
              onClick={previousAction}
              data-test="back-icon-button"
            >
              {previousText}
            </IconButton>
          )}
        </div>
        <div className="flex items-center">
          {showBasketCount && (
            <div className="flex">
              {showBasketText && (
                <IconButton
                  onClick={onBasketClick}
                  iconReverse={true}
                  Icon={ArrowRight}
                  data-test="view-order-icon-button"
                >
                  {basketText}
                </IconButton>
              )}
              <BasketCount count={count} onClick={onBasketClick} Icon={CartIconImage} />
            </div>
          )}
          {showSubtotal && (
            <div className="ml-8 text-4xl font-bold" data-test="header-cart-subtotal">
              {total}
            </div>
          )}
          {isCouponEnabled && showCouponButton && (
            <SecondaryButton
              className="ml-8 text-4xl font-bold"
              onClick={couponAction}
              data-test="enter-offer-button"
            >
              {couponText}
            </SecondaryButton>
          )}
        </div>
      </div>
    </div>
  );
};

export { Header };
