// App is for all the internal product setup.
import React from 'react';
import whyDidYouRender from '@welldone-software/why-did-you-render';
import { register as registerServiceWorker } from '@fingermarkglobal/service-worker/register';

import { Routes } from './routes';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { FallbackRender } from '@fingermarkglobal/cringer.components';
import { Decoration } from './src/components/stateless/decoration';

const ldConfig = {
  clientSideID: process.env.POI_APP_LAUNCHDARKLY_CLIENT_SIDE_ID,
  context: {
    kind: 'multi',
    organisation: {
      key: process.env.POI_APP_ORGANISATION_ID,
    },
  },
};

registerServiceWorker({ path: './serviceWorker.js ' });

if (process.env.NODE_ENV === 'development') {
  whyDidYouRender(React, {
    trackAllPureComponents: true,
  });
}

const Application = withLDProvider(ldConfig)(() => (
  <FallbackRender brandHeader={<Decoration showLogo={true} />}>
    <Routes />
  </FallbackRender>
));

export { Application };
