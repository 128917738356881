import React from 'react';

import {
  PaymentMethods,
  BannerWarning,
  ServiceCardButton,
  HomeCustomisation,
} from '@fingermarkglobal/cringer.components';

import Logo from '../../../assets/images/logo.svg';
import DineInImage from '../../../assets/images/dine_in.svg';
import TakeAwayImage from '../../../assets/images/take_away.svg';
import VaccinePassImage from '../../../assets/images/vaccine_pass.png';

const PrimaryHome = ({
  t = () => {},
  card,
  cash,
  icons,
  className = '',
  healthMsg = true,
  primaryAction,
  secondaryAction,
  accessibilityEnabled = false,
  showTermsAndConditionsButton = false,
  setShowTermsAndConditionsModal = () => {},
}) => {
  const isBordered = !!(icons?.length || card);

  return (
    <HomeCustomisation.Provider>
      <HomeCustomisation.Background />

      <div
        data-test="primary-home"
        className={`flex z-10 flex-col justify-between items-center h-full w-full overflow-y-hidden  ${className}`}
      >
        <div
          className="flex flex-col items-center justify-start w-full h-full mb-32"
          data-test="home-upper"
        >
          <PaymentMethods
            t={t}
            card={card}
            cash={cash}
            icons={icons}
            Logo={Logo}
            enableLogo={!accessibilityEnabled}
            className={`px-12 py-4 text-7xl ${isBordered ? 'border-2 border-black' : ''}  rounded ${
              isBordered && !accessibilityEnabled ? 'mt-32' : ''
            }`}
          />

          {!accessibilityEnabled && (
            <div className="flex flex-col items-center justify-center mt-32 leading-none">
              <p className="font-extrabold text-black text-12xl" data-test="home-title">
                {t('homeTitle')}
              </p>

              <p className="font-extrabold text-black text-9xl" data-test="home-subtitle">
                {t('homeSubtitle')}
              </p>
            </div>
          )}
        </div>

        <div
          className="flex flex-col-reverse items-center justify-start w-full h-full"
          data-test="home-lower"
        >
          {showTermsAndConditionsButton && (
            <BannerWarning
              data-test="banner-terms"
              t={t}
              width="109"
              className={`w-4/5 text-white bg-primary mb-${accessibilityEnabled ? '28' : '64'}`}
              title={t('homeTermsAndConditionsBannerWarningTitle')}
              description={t('homeTermsAndConditionsBannerWarningDescription')}
              additionalDescription={t('homeTermsAndConditionsBannerWarningAdditionalDescription')}
              additionalDescriptionAction={() => setShowTermsAndConditionsModal(true)}
              additionalDescriptionColor={'text-blue-200'}
            />
          )}

          {healthMsg && (
            <BannerWarning
              t={t}
              width="109"
              data-test="banner-health"
              image={VaccinePassImage}
              className={`w-4/5 text-white bg-primary mb-${
                showTermsAndConditionsButton ? '2' : accessibilityEnabled ? '28' : '64'
              }`}
            />
          )}

          <div
            data-test="home-services"
            className={`flex items-stretch justify-center w-4/5 ${
              healthMsg ? 'mb-32' : 'mb-96'
            } space-x-16`}
          >
            {!!secondaryAction && (
              <ServiceCardButton
                className="w-1/2 shadow-lg"
                data-test="button-secondary"
                onClick={secondaryAction}
              >
                <div className="mt-4">{t('homeSecondaryAction')}</div>
                <DineInImage className="w-64" />
              </ServiceCardButton>
            )}

            {!!primaryAction && (
              <ServiceCardButton
                className="w-1/2 shadow-lg"
                data-test="button-primary"
                onClick={primaryAction}
              >
                <div className="mt-4">{t('homePrimaryAction')}</div>
                <TakeAwayImage className="w-32" />
              </ServiceCardButton>
            )}
          </div>
        </div>
      </div>
    </HomeCustomisation.Provider>
  );
};

export { PrimaryHome };
